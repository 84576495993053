html,
body {
	/* https://www.smashingmagazine.com/2016/05/fluid-typography/ */
	/* https://css-tricks.com/snippets/css/fluid-typography/ */
	/* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
	font-size: calc(12px + (16 - 12) * ((100vw - 350px) / (1366 - 350)));
	/* font-size: calc(0.15em + 1vw); */
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
@media only screen and (min-width: 1367px) {
	html,
	body {
		font-size: calc(16px + (24 - 16) * ((100vw - 1366px) / (1900 - 1366)));
		/* background-color: blue; */
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.MuiPickersCalendarHeader-transitionContainer {
	height: 33px;
}
